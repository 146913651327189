<template>
    <v-flex fill-height>
        <v-layout justify-space-between pa-2 row>
            <v-flex v-t="'sa.support.actions.add-admin-group-role-to-user.title'" headline shrink />
        </v-layout>
		<v-layout fill-height pa-4>
            <v-flex>
                <v-layout column justify-center>
                    <v-flex my-3 shrink>
                        <v-layout column>
                            <v-flex v-t="'sa.support.actions.add-admin-group-role-to-user.accounting-firm'" class="title" mb-3 />
                            <v-autocomplete
                                v-model="selectedAccountingFirmId"
                                color="primary"
                                :items="accountingFirms"
                                :label="$t('accounting_firm')"
                                :loading="loadingAccountingFirmsList"
                            />
                            <w-text-input
v-model="userEmail" auto-focus
					:label="$t('sa.support.actions.add-admin-group-role-to-user.user-email')" maxlength="191"
					type="email" required></w-text-input>
                            </v-layout>
                    </v-flex>
                    <v-flex my-3 shrink>
                        <WButton :disabled="!selectedAccountingFirmId || !userEmail" @click="execute">{{ $t('actions.launch') }}</WButton>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-flex>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'
export default {
    name: 'AddAdminGroupRoleToUser',
    mixins: [SuperAdminModuleGuard],
    data: function () {
		return {
			accountingFirms: new Array(),
            selectedAccountingFirmId: null,
            userEmail: null,
            loadingAccountingFirmsList: false,
        }
    },
    created: function () {
		this.getAccountingFirms()
	},
    methods: {
        getAccountingFirms: function () {
			this.loadingAccountingFirmsList = true
			this.service
				.getAccountingFirms()
				.then(accountingFirms => {
					return accountingFirms.map(accountingFirm => {
						return {
							text: accountingFirm.name,
							value: accountingFirm.id
						}
					})
				})
				.then(accountingFirms => {
					this.accountingFirms = accountingFirms
				})
				.finally(() => {
					this.loadingAccountingFirmsList = false
				})
		},
        execute: function () {
            return this.service.executeCommand({ 
                    command: "support:admin-group:assign", 
                    params: {
                        'accounting-firm-id': this.selectedAccountingFirmId, 
                        'user-email': this.userEmail, 
                        '--force': true,
                    }
            }).then(() => {
                this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.support.success'))
            }).catch(error => {
                this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error)
            })
        }
    }
}
</script>